import { graphql } from 'gatsby'
import * as React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Link from 'gatsby-link'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

import Layout from '../components/Layout/Layout'
import { Location, getLocale } from '../locales'

import './airlines.scss'

type Props = {
    data: any
    pageContext: {
        localeSlugs: { [key: string]: { [key: string]: string } }
        localizations: { [category: string]: { [label: string]: string } }
    }
    location: Location
}

export default class AirlinesIndexPage extends React.Component<Props> {
    render() {
        const { localeSlugs, localizations } = this.props.pageContext
        const locale = getLocale(this.props.location)

        return (
            <Layout pageContext={this.props.pageContext}>
                <Container className="airlines-index">
                    <h1>Airlines</h1>
                    {this.props.data.articles.edges.map(article => {
                        const link = `/${locale.shortCode}${localeSlugs['/airlines/'][locale.code]}${article.node.slug}`
                        const titleImage = getImage(article.node.titleImage)
                        return (
                            <Row className="article" key={article.node.id}>
                                <Col md={4}>
                                    <GatsbyImage
                                        image={titleImage}
                                        style={{ maxWidth: '100%' }}
                                        alt={article.node.titleImage.title}
                                        title={article.node.titleImage.title}
                                    />
                                </Col>
                                <Col md={8}>
                                    <h2>
                                        <Link to={link}>{article.node.title}</Link>
                                    </h2>
                                    <p>{article.node.teaser.teaser}</p>
                                    <Link to={link}>{localizations['news']['continue-reading']}</Link>
                                </Col>
                            </Row>
                        )
                    })}
                </Container>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
    query AirlinesIndexPageContent($locale: String!) {
        articles: allContentfulAirlinePage(
            filter: { node_locale: { eq: $locale }, slug: { ne: null } }
            sort: { fields: [title], order: ASC }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    teaser {
                        teaser
                    }
                    titleImage {
                        title
                        gatsbyImageData(layout: CONSTRAINED, width: 360, height: 240)
                    }
                }
            }
        }
    }
`
